<template>
  <div class='output popUpScrollList'>
    <div v-if="notifications.length===0 && !isLoading" class='element materialShadow'>
      <div class='emptyList'>
        <i class='fa fa-exclamation-circle'></i><br>{{ x('notificationsEmpty') }}
      </div>
    </div>

    <div v-if="notifications.length>0 && !isLoading" class='element materialShadow center delete'>
      <button class='lsc-button lsc-rounded-10 grey b_delete_notifications'
              @click="deleteNotifications()">
        <template v-if="showsConfirm">
          <i class='fa fa-exclamation-circle'></i>{{ x('areYouSure') }}
        </template>
        <template v-else-if="isDeleting">
          <i class='fa fa-cog fa-spin'></i>{{ x('deletingDots') }}
        </template>
        <template v-else>
          <i class='fa fa-trash'></i>{{ x('deleteNotifications') }}
        </template>
      </button>
      <span class='delete_notifications'>{{ x('deletedNotificationsCannotBeRestored') }}</span>
    </div>

    <notification v-for="notification in notifications"
                  :notification="notification"
                  :key="notification.id"/>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { httpGet, httpDelete } from '@/classes/httpHelper';
  import ComponentEventNames from '@/enums/component-event-names';
  import translate from '../Mixins/Translate';
  import Notification from './Notification';

  export default {
    name: 'notifications-list',
    mixins: [translate],
    emits: [ComponentEventNames.didLoad],
    components: {
      notification: Notification,
    },
    data() {
      return {
        notifications: [],
        isLoading: true,
        showsConfirm: false,
        isDeleting: false,
      };
    },
    computed: mapState(['config', 'user']),
    async mounted() {
      this.notifications = await httpGet(`users/me/notifications?userrole=${this.user.userRole}`);
      for (const notification of this.notifications) {
        const temp = JSON.parse(notification.parameter);
        for (const prop in temp) {
          if (prop in temp) {
            notification[prop] = temp[prop];
          }
        }
      }
      this.isLoading = false;
      this.$emit(ComponentEventNames.didLoad);
    },
    methods: {
      async deleteNotifications() {
        if (this.showsConfirm) {
          this.isDeleting = true;
          try {
            await httpDelete(`users/me/notifications?userrole=${this.user.userRole}`);
            this.notifications = [];
          } catch (e) {
            // eslint-disable-next-line no-alert
            alert(`Error while deleting: "${e.responseText}". Status: ${e.status}`);
          }
          this.isDeleting = false;
          this.showsConfirm = false;
        } else {
          this.showsConfirm = true;
          setTimeout(() => { this.showsConfirm = false; }, 2000);
        }
      },
    },
  };
</script>
