<template>
  <div class="element materialShadow notification" :class="notification.visit">
    <ul>
      <li class="top">
        <span class="timestamp">{{ prettyTime(notification.time, config.translation) }}</span>
        <h1 v-html="headline"/>
      </li>
      <li class="content">
        <img  v-if="notification.image" :src="config.imageBaseUrl+notification.image">
        <div v-html="text"/>
      </li>
      <li class="footer" v-if="buttonLabel !== null">
        <button class="lsc-button lsc-rounded-10 ThemeNotificationsButton"
                @click="loadVisit()">{{ buttonLabel }}
        </button>
      </li>
    </ul>
    <div class="lsc-clear"></div>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { mapState } from 'vuex';
  import NotificationNames from '@/enums/notification-names';
  import translate from '../Mixins/Translate';
  import prettyTime from '../../classes/pretty-time';

  export default {
    name: 'notification',
    props: ['notification'],
    data() {
      return {
        headline: '',
        text: '',
        buttonLabel: this.x('seeVisit'),
      };
    },
    mixins: [translate],
    computed: mapState(['config']),
    mounted() {
      this.getPhrases();
    },
    methods: {
      getPhrases() {
        const count = this.notification.count - 1;
        switch (this.notification.action) {
          case NotificationNames.liked:
            this.headline = this.x('newLike');
            this.text = `<B>${this.notification.user}</b>${this.x('gaveLikeToThisVisit')}`;
            break;
          case NotificationNames.commented:
            this.headline = this.x('newComment');
            this.text = `<p><b>${this.notification.user}</b>${this.x('wroteCommentForThisVisit')}</p>`;
            this.text += `<p>&nbsp;</p><p><i>${decodeURI(this.notification.comment)}</i></p>`;
            break;
          case NotificationNames.rfiCreated:
            this.headline = this.x('newRFIVisit');
            this.text = `<b>${this.notification.user}</b>${this.x('madeNewRFIVisitYourCustomer')}`;
            break;

          case NotificationNames.likedMultiple:
            this.headline = this.x('newLikes');
            if (count === 1) {
              this.text = `<b>${
                this.notification.user}</b> ${this.x('and').toLowerCase()} <b>1 ${
                this.x('other').toLowerCase()}</b>${this.x('gaveLikesToThisVisit')}`;
            } else {
              this.text = `<b>${this.notification.user}</b> ${
                this.x('and').toLowerCase()} <b>${this.notification.count - 1} ${
                this.x('others').toLowerCase()}</b>${this.x('gaveLikesToThisVisit')}`;
            }
            break;

          case NotificationNames.created:
            this.headline = this.x('newVisit');
            this.text = `<b>${this.notification.user}</b>${this.x('createdANewVisit')}`;
            break;

          case NotificationNames.ideaCreated:
            this.headline = this.x('newIdea');
            this.text = `<b>${this.notification.user}</b>${this.x('postedANewIdea')}`;
            this.buttonLabel = this.x('seeIdea');
            break;

          case NotificationNames.visitDeleted:
            this.headline = this.x('visitDeleted');
            this.text = `<b>${this.notification.user}</b>${this.x('deletedThisVisit')}`;
            this.buttonLabel = null;
            break;

          case NotificationNames.ideaChangedToVisit:
            this.headline = this.x('ideaChanged');
            this.text = `<b>${this.notification.user}</b> ${this.x('changedAnIdeaToAnotherType')}`;
            break;

          case NotificationNames.customerDeleted:
            this.headline = this.x('customerDeleted');
            this.text = this.x('customerWasDeletedByUser')
              .replace('#1', this.notification.name)
              .replace('#2', this.notification.user);
            this.buttonLabel = null;
            break;

          case NotificationNames.visitPlanCompleted:
            this.headline = this.x('visitPlanCompleted')
              .replace('#1', this.notification.visitPlan);
            this.text = this.x('visitPlanNameWasCompleted');
            this.buttonLabel = null;
            break;

          case NotificationNames.attachedToVisitPlan:
            this.headline = this.x('attachedToVisitPlan');
            this.text = this.x('youWereAttachedToVisitPlan')
              .replace('#1', this.notification.visitPlan);
            this.buttonLabel = null;
            break;

          case NotificationNames.removedFromVisitPlan:
            this.headline = this.x('removedFromVisitPlan');
            this.text = this.x('youWereRemovedFromVisitPlan')
              .replace('#1', this.notification.visitPlan);
            this.buttonLabel = null;
            break;

          default:
            this.headline = this.notification.action;
            break;
        }
      },
      loadVisit() {
        const ItemDetails = defineAsyncComponent(() => import('../Pages/ItemDetails'));
        this.$store.commit('pushPopup', {
          component: ItemDetails,
          title: this.x('visit'),
          params: {
            id: this.notification.visit,
          },
        });
      },
      prettyTime,
    },
  };
</script>

<style scoped lang="scss">
h1 {
  text-transform: uppercase;
  margin: 0;
  font-weight: 700;
  color: var(--ThemeScreenTitleColor);
}

.timestamp {
  margin: 0 0 0 20px;
  display: block;
  float: right;
  font-size: 10px;
  color: #bdc3c7;
  text-align: right;
}
</style>
