/**
 * These are the different types of notifications that can be
 * sent from the server and shown in the notification lists.
 */
export default {
  liked: 'liked',
  commented: 'commented',
  rfiCreated: 'rfi_created',
  likedMultiple: 'likedMultiple',
  created: 'created',
  ideaCreated: 'idea_created',
  visitDeleted: 'visit_deleted',
  ideaChangedToVisit: 'idea_changed_to_visit',
  customerDeleted: 'customer_deleted',
  visitPlanCompleted: 'visitplan-completed',
  attachedToVisitPlan: 'attached-to-visit-plan',
  removedFromVisitPlan: 'removed-from-visit-plan',
};
